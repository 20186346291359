import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SignUpForm from '../components/SignUp'
import { FormPillow } from '../styles/globalStyles'

const SignUpPage = () => (
  <Fragment>
    <h1>SignUp</h1>
    <FormPillow>
      <SignUpForm />
    </FormPillow>
  </Fragment>
)

export default () => (
  <Layout>
    <SignUpPage />
  </Layout>
)
